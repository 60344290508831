<template>
<transition name="fadeTop" mode="out-in" appear>
    <div>
        <div class="top_nav">
            <div class="l_c">
                <transition name="fadeLogo" mode="out-in" appear>
                <router-link :to="{name:'BiAnBao'}"><img src="../../../../../public/images/BAB/logo.png" alt=""></router-link>
                </transition>
            </div>
            <div class="l_r">
            <a href="javascript:;" class="lang_img" @click="changeLan"><img :src="changeLangImg" alt=""></a>
            <a href="javascript:;" v-if="connectedStatus==0" class="btn_connect" @click="openDialog(connectedStatus,1)">{{$t('menu.connect')}}</a>
            <a href="javascript:;" v-if="connectedStatus==1" class="btn_connected">{{$t('menu.connected')}}</a>
            <a href="javascript:;" class="more_img" @click="openNavDialog(connectedStatus,1)"><img src="../../../../../public/images/top_more.png" alt=""></a>
            </div>
        </div>
        <div v-if="RightNavShow"><Bab-Right :conStatus="connectedStatus" @transfer="openNavDialog"></Bab-Right></div>
        <Bab-Wallet v-if="dialogShow" :id="0" @transfer="openDialog"></Bab-Wallet>
    </div>
</transition>
</template>

<script>
import BabRight from './BabRight.vue';
import BabWallet from './BabWallet.vue';
export default {
    components:{BabRight,BabWallet},
    data() {
        return {
            RightNavShow:false,//是否弹出侧边导航栏
            connectedStatus:0,//未连接=0;连接成功=1
            changeLangImg:require("../../../../../public/images/lang.png"),
            dialogShow:false,
        }
    },
    created:function(){
        var address = localStorage.getItem('address')
        if(address){
            this.connectedStatus = 1
        }
    },
    methods:{
        openNavDialog:function(connectStatus,type){
            if(type==1){//type=1 打开右边导航栏弹窗，type=2 关闭右边导航栏弹窗
                this.RightNavShow=true;
            }
            if(type==2){
                this.RightNavShow=false;
                this.connectedStatus = connectStatus;
            }
        },
        changeLan(){
            // console.log(this.$i18n,'=============');
            let command = this.$i18n.locale;
            if(command == 'en') {
                this.$i18n.locale = 'zh';
                this.changeLangImg = require("../../../../../public/images/lang.png");
            } else if (command == 'zh') {
                this.$i18n.locale = 'en';
                this.changeLangImg = require("../../../../../public/images/top_zh.png");
            }
        },
        openDialog(connectStatus,type){
            if(type==1){//type=1 打开钱包弹窗，type=2 关闭钱包弹窗
                this.dialogShow=true;
                console.log(connectStatus,'==',type);
            }
            if(type==2){
                this.dialogShow=false;
                this.connectedStatus = connectStatus;
            }
        }
    }
}
</script>

<style>
.btn_connect{
    background: #FECA39!important;
    color: #753127!important;
}
.btn_connected{
    border: 0.1rem solid #3b3010!important;
    color: #FCC413!important;
}
</style>